/**
 * In this mixin we are including custom styled components mixins
 */
@use 'src/app/customers/components/customers/customers.component.theme' as customers;
@use 'src/app/customers/components/customer-editor/customer-editor.component.theme' as customer-editor;
@use 'src/app/customers/components/places-list/places-list.component.theme' as places-list;
@use 'src/app/dispatcher-notifications/components/dispatcher-notifications/dispatcher-notifications.component.theme' as
  dispatcher-notifications-theme;
@use 'src/app/dashboard-map/components/dashboard-map/dashboard-map.component.theme' as dashboard-map;
@use 'src/app/dashboard-scheduler/components/dashboard-scheduler/dashboard-scheduler.component.theme' as
  dashboard-scheduler;
@use 'src/app/dashboard-scheduler/components/scheduler-toolbar/scheduler-toolbar.component.theme' as scheduler-toolbar;
@use 'src/app/dashboard-scheduler/components/team-column-header/team-column-header.component.theme' as
  team-column-header;
@use 'src/app/shared/forms/place-unavailabilities/place-unavailabilities/place-unavailabilities.component.theme' as
  place-unavailabilities;
@use 'src/app/tasks/components/appointments-table/appointments-table.component.theme' as appointments-table;
@use 'src/app/tasks/components/task-addres-type/task-address-type.component.theme' as task-address-type;
@use 'libs/shared/card/src/lib/_card.component.theme.scss' as card;
@use 'mat-button.theme' as mat-button;
@use 'mat-form-field.theme' as mat-form-field;
@use 'src/app/tasks-report/components/tasks-report/tasks-report.component.theme' as tasks-report-theme;
@use 'src/app/settings/components/settings/settings.component.theme' as settings-theme;
@use 'src/app/task-additional-fields-config/components/task-additional-fields-config/task-additional-fields-config.component.theme'
  as task-details-config;

@use './custom-classes.theme' as custom-classes;

@import 'src/app/dashboard/components/dashboard-toolbar/dashboard-toolbar.component.theme';
@import 'src/app/dashboard-list/components/dashboard-list/dashboard-list.component.theme';
@import 'src/app/orders/components/order-editor-task-list/order-editor-task-list.component.theme';
@import 'src/app/shared/area-date-range-form/area-date-range-form.component.theme';
@import 'mat-table.theme';
@import 'src/app/form-templates/components/form-template-editor/form-template-editor.component.theme';
@import 'src/app/schedule/components/schedule/schedule.component.theme';
@import 'src/app/schedule/components/datepicker-form-field-control/datepicker-form-field-control.component.theme';
@import 'src/app/car-tracking-report/components/car-tracking-report/car-tracking-report.component.theme';

@mixin theme($theme) {
  @include dashboard-toolbar-theme($theme);
  @include dashboard-scheduler.theme($theme);
  @include dashboard-map.theme($theme);
  @include scheduler-toolbar.theme($theme);
  @include team-column-header.theme($theme);
  @include dashboard-list-theme($theme);
  @include order-editor-task-list-theme($theme);
  @include place-unavailabilities.theme($theme);
  @include appointments-table.theme($theme);
  @include task-address-type.theme($theme);
  @include area-date-range-form-theme($theme);
  @include mat-table-custom-theme($theme);
  @include form-template-editor-theme($theme);
  @include glb-schedule($theme);
  @include glb-car-tracking-report($theme);
  @include dispatcher-notifications-theme.theme($theme);
  @include mat-button.theme($theme);
  @include mat-form-field.theme($theme);
  @include tasks-report-theme.theme($theme);
  @include settings-theme.theme($theme);
  @include card.theme($theme);
  @include customers.theme($theme);
  @include customer-editor.theme($theme);
  @include places-list.theme($theme);
  @include task-details-config.theme($theme);
  @include custom-classes.theme($theme);
  @include datepicker-form-field-control-theme($theme);
}

@import './glb-grid.theme';

@mixin typography($config) {
  @include dashboard-toolbar-typography($config);
  @include dashboard-scheduler.typography($config);
  @include dashboard-map.typography($config);
  @include scheduler-toolbar.typography($config);
  @include team-column-header.typography($config);
  @include area-date-range-form-typography($config);
  @include glb-grid-typography($config);
  @include glb-schedule-typography($config);
  @include glb-car-tracking-report-typography($config);
  @include dispatcher-notifications-theme.typography($config);
  @include place-unavailabilities.typography($config);
  @include appointments-table.typography($config);
  @include task-address-type.typography($config);
  @include settings-theme.typography($config);
  @include mat-button.typography($config);
  @include mat-form-field.typography($config);
  @include card.typography($config);
  @include customers.typography($config);
  @include customer-editor.typography($config);
  @include places-list.typography($config);
  @include task-details-config.typography($config);
  @include custom-classes.typography($config);
}
