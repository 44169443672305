@use '@angular/material' as mat;

@mixin order-editor-task-list-theme($theme) {
  $warn: map-get($theme, warn);

  glb-order-editor-task-list {
    .mat-table .mat-row.expired .mat-cell {
      color: mat.get-color-from-palette($warn, default);
    }
  }
}
