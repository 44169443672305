@use '@angular/material' as mat;
@use 'sass:map';

@mixin color($theme) {
  $color-config: mat.get-color-config($theme);
  $primary: map.get($color-config, primary);
  $accent: map.get($color-config, accent);
  $warn: map.get($color-config, warn);
  $foreground: map.get($color-config, foreground);
  $background: map.get($color-config, background);
  $is-dark-theme: map.get($color-config, is-dark);

  glb-scheduler-toolbar {
    border-bottom: 1px solid mat.get-color-from-palette($foreground, divider);

    button.zoom-control {
      &:not(:last-child) {
        border-right: 1px solid mat.get-color-from-palette($foreground, divider);
      }

      &:not([class*='mat-elevation-z']) {
        box-shadow: 0 1px 5px mat.get-color-from-palette($foreground, divider);
      }
    }

    .mat-form-field-appearance-outline .mat-form-field-outline-start,
    .mat-form-field-appearance-outline .mat-form-field-outline-end {
      border-radius: 1.5px;
    }
  }
}

@mixin typography($theme) {
  $typography-config: mat.get-typography-config($theme);
}

@mixin theme($theme) {
  $color-config: mat.get-color-config($theme);
  @if $color-config != null {
    @include color($theme);
  }

  $typography-config: mat.get-typography-config($theme);
  @if $typography-config != null {
    @include typography($theme);
  }
}
