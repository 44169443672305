@use '@angular/material' as mat;
@use 'sass:map';

@mixin color($theme) {
  $color-config: mat.get-color-config($theme);
  $primary: map.get($color-config, 'primary');
  $background: map.get($color-config, 'background');
  $foreground: map.get($color-config, 'foreground');
  $warn: map.get($color-config, warn);

  glb-dispatcher-notifications {
    $palette: mat.$grey-palette;
    $hue: 100;
    $hover-hue: 200;

    .mat-expansion-panel {
      .mat-expansion-panel-header {
        background: mat.get-color-from-palette($palette, $hue);

        .mat-expansion-panel-header-title.action {
          color: mat.get-color-from-palette($warn);
        }
        .mat-expansion-panel-header-title.inform {
          color: mat.get-color-from-palette($primary);
        }

        &.mat-expanded {
          &:hover,
          &:focus {
            background: mat.get-color-from-palette($palette, $hover-hue);
          }
        }
      }

      .mat-expansion-panel-header.cdk-keyboard-focused,
      .mat-expansion-panel-header.cdk-program-focused,
      &:not(.mat-expanded) .mat-expansion-panel-header:hover {
        &:not([aria-disabled='true']) {
          background: mat.get-color-from-palette($palette, $hover-hue);
        }
      }
    }

    .mat-list-base {
      .mat-subheader {
        color: mat.get-color-from-palette($foreground, text);
      }
      .mat-subheader.action {
        color: mat.get-color-from-palette($warn);
      }
      .mat-subheader.inform {
        color: mat.get-color-from-palette($primary);
      }
    }
  }
}

@mixin typography($theme) {
  $typography-config: mat.get-typography-config($theme);

  glb-dispatcher-notifications {
    .mat-expansion-panel-header-title {
      font: {
        family: mat.font-family($typography-config, subheading-1);
        size: mat.font-size($typography-config, subheading-1);
        weight: mat.font-weight($typography-config, title);
      }
    }

    .mat-list-base {
      .mat-subheader {
        font: {
          family: mat.font-family($typography-config, subheading-2);
          size: mat.font-size($typography-config, subheading-2);
          weight: mat.font-weight($typography-config, title);
        }
      }
    }
  }
}

@mixin theme($theme) {
  $color-config: mat.get-color-config($theme);
  @if $color-config != null {
    @include color($theme);
  }

  $typography-config: mat.get-typography-config($theme);
  @if $typography-config != null {
    @include typography($theme);
  }
}
