@use '@angular/material' as mat;
@import 'variables';

@mixin form-template-editor-theme($theme) {
  $primary: map-get($theme, primary);
  $accent: map-get($theme, accent);
  $warn: map-get($theme, warn);
  $foreground: map-get($theme, foreground);
  $background: map-get($theme, background);

  .cdk-drag-preview.form-template-field-drag-preview {
    border-radius: $panel-border-radius;
    background-color: mat.get-color-from-palette($background, card);
  }

  glb-form-template-editor {
    .cdk-drag:hover {
      @include mat.elevation(3);
    }
  }
}
