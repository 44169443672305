@use '@angular/material' as mat;
@import '@angular/material/theming';

@mixin mat-table-custom-theme($theme) {
  $primary: map-get($theme, primary);
  $accent: map-get($theme, accent);
  $warn: map-get($theme, warn);
  $background: map-get($theme, background);
  $foreground: map-get($theme, foreground);
  $is-dark-theme: map-get($theme, is-dark);

  $table-text-color: if($is-dark-theme, $light-primary-text, $dark-primary-text);

  $selection-color: $primary;
  $selection-hue: if($is-dark-theme, 800, 100);

  $border-color: mat.get-color-from-palette($foreground, divider);
  $cell-border: 1px solid $border-color;
  $cell-horizontal-padding: 1rem;
  $radius: 4px;

  .mat-table {
    .mat-form-field {
      .mat-input-element {
        color: $table-text-color;
      }

      &.mat-form-field-appearance-outline {
        & .mat-form-field-outline-start,
        .mat-form-field-outline-gap,
        .mat-form-field-outline-end {
          background: mat.get-color-from-palette($background, background);
        }
      }
    }

    .mat-header-row:first-child > .mat-header-cell {
      border-top: $cell-border;

      &:first-child {
        border-top-left-radius: $radius;
      }

      &:last-child {
        border-top-right-radius: $radius;
      }
    }

    .mat-row {
      &:nth-child(even) {
        .mat-cell {
          background: mat.get-color-from-palette($background, app-bar);
        }
      }

      &.selected {
        .mat-cell {
          background: mat.get-color-from-palette($selection-color, $selection-hue);
          color: mat.get-contrast-color-from-palette($selection-color, $selection-hue);
        }
      }
    }

    .mat-cell,
    .mat-header-cell,
    .mat-footer-cell {
      border: {
        bottom: $cell-border;
        right: $cell-border;
      }

      &:first-child,
      &.sticky-end {
        border-left: $cell-border;
      }

      &:not(:first-of-type) {
        padding-left: $cell-horizontal-padding;
      }

      &:not(:last-of-type) {
        padding-right: $cell-horizontal-padding;
      }
    }

    .mat-cell {
      cursor: pointer;
    }

    .mat-header-cell {
      background-color: mat.get-color-from-palette($background, app-bar);
      color: $table-text-color;
    }
  }
}
