@use './colors' as colors;
@import '../status-colors.map';
@import 'appointment-colors.map';

@mixin task-background-color($primary-color) {
  background-color: $primary-color;
  border-color: darken($primary-color, 10%);
}

.b-sch-event.task {
  justify-content: center;
  border-width: 1px;
  border-style: solid;
  @include task-background-color(transparent);

  @each $status-name, $color in $status-colors {
    &.task--status-#{$status-name} {
      @include task-background-color($color);
    }
  }

  @each $status-name, $color in $appointment-colors {
    &.task--status-#{$status-name}.task--appointment {
      @include task-background-color($color);

      &.task--overlaps-unavailability {
        @include task-background-color(colors.$conflict-color);
      }
    }
  }

  &.task--disabled {
    @include task-background-color(colors.$disabled-color);
  }

  &.task--type-emergency {
    border-bottom: 4px solid colors.$conflict-color;
  }

  &.task--overlaps-unavailability {
    @include task-background-color(colors.$conflict-color);
  }

  &.task--locked-by-optimization {
    font-weight: bold;
    @include task-background-color(gray);
  }
  &.task--timeout {
    @include task-background-color(colors.$timeout-color);
  }
}
