@use '@angular/material' as mat;
@use 'sass:map';

@mixin color($theme) {
  $color-config: mat.get-color-config($theme);
  $primary: map.get($color-config, primary);
  $accent: map.get($color-config, accent);
  $warn: map.get($color-config, warn);
  $foreground: map.get($color-config, foreground);
  $background: map.get($color-config, background);
  $is-dark-theme: map.get($color-config, is-dark);

  glb-customer-editor {
    glb-tasks-list {
      header > .mat-caption {
        color: mat.get-color-from-palette($accent, 900);
      }
    }
  }
}

@mixin typography($theme) {
  $typography-config: mat.get-typography-config($theme);
}

@mixin theme($theme) {
  $color-config: mat.get-color-config($theme);
  @if $color-config != null {
    @include color($theme);
  }

  $typography-config: mat.get-typography-config($theme);
  @if $typography-config != null {
    @include typography($theme);
  }
}
