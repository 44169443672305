@use '@angular/material' as mat;

@mixin dashboard-list-theme($theme) {
  $primary: map-get($theme, primary);
  $accent: map-get($theme, accent);
  $warn: map-get($theme, warn);
  $foreground: map-get($theme, foreground);
  $background: map-get($theme, background);

  glb-dashboard-list {
    .mat-table .mat-row {
      &.expired .mat-cell {
        color: mat.get-color-from-palette($warn, default);
      }

      &.locked-by-optimization .mat-cell {
        color: mat.get-color-from-palette($foreground, disabled-text);
      }
    }
  }
}
