@use '@angular/material' as mat;

@mixin theme($theme) {
  $warn: map-get($theme, warn);

  glb-tasks-report {
    .mat-table .mat-row {
      &.expired .mat-cell {
        color: mat.get-color-from-palette($warn, default);
      }
    }
  }
}
