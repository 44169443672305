@import 'src/styles/mixins/form-field-without-label.theme';
@import 'src/styles/mixins/form-field-without-subscript.theme';
@import 'src/styles/mixins/text-ellipsis';

@mixin glb-grid-typography($config) {
  $cell-horizontal-padding: 1rem;

  glb-grid {
    tr.mat-header-row {
      &.filter-row > th.mat-header-cell {
        padding: 0.25rem $cell-horizontal-padding;

        & .mat-form-field {
          width: 100%;

          @include mat-form-field-without-label-typography($config);
          @include mat-form-field-without-subscript-typography($config);
          @include _mat-form-field-typography($config);

          .mat-form-field-suffix {
            display: flex;
          }

          .mat-form-field-infix {
            width: 50px;
          }
        }

        &:first-child {
          padding-left: $cell-horizontal-padding * 1.5;
        }

        &:last-child {
          padding-right: $cell-horizontal-padding * 1.5;
        }
      }

      & > th.mat-header-cell {
        @include text-ellipsis;
      }

      &:not(:last-of-type) {
        th.mat-header-cell[rowspan='1'],
        th.mat-header-cell:not([rowspan]) {
          border-bottom-width: 0;
        }
      }
    }

    tr.mat-row {
      cursor: pointer;

      .mat-cell {
        @include text-ellipsis;
      }
    }
  }
}

@mixin _mat-form-field-typography($config) {
  $infix-padding: 0.5em;
  $subscript-margin-top: 0;

  .mat-form-field-infix {
    padding: $infix-padding 0;
  }

  .mat-form-field-subscript-wrapper {
    margin-top: $subscript-margin-top;
    top: 100%;
  }

  .mat-form-field-suffix {
    align-self: center;

    .mat-icon-button {
      height: 2em;
      width: 2em;
    }
  }
  @include _mat-form-field-outline-typography($config);
}

@mixin _mat-form-field-outline-typography($config) {
  $infix-padding: 0.5em;

  &.mat-form-field-appearance-outline {
    .mat-form-field-flex {
      padding: 0 $infix-padding;
    }

    .mat-select-arrow-wrapper {
      transform: unset;
    }
  }
}
