@use '@angular/material' as mat;
@import 'src/styles/mixins/toolbar-form.theme';

@mixin _dashboard-toolbar-button-toggle-theme($theme) {
  $primary: map-get($theme, primary);
  $accent: map-get($theme, accent);
  $warn: map-get($theme, warn);
  $foreground: map-get($theme, foreground);
  $background: map-get($theme, background);
  $is-dark-theme: map-get($theme, is-dark);
  $color: $primary;
  $hue: 200;

  .mat-button-toggle-group-appearance-standard {
    border: none;
    background: mat.get-color-from-palette($color, text);
  }

  .mat-button-toggle-group-appearance-standard {
    border-radius: 1.5px;
  }

  .mat-button-toggle-appearance-standard {
    color: mat.get-color-from-palette($color, text);
    background: mat.get-color-from-palette($color, $hue);
  }

  .mat-button-toggle-appearance-standard.mat-button-toggle-checked {
    color: mat.get-color-from-palette($color, 800);
    background: mat.get-color-from-palette($background, raised-button);
  }

  .mat-divider {
    color: mat.get-color-from-palette($color, 800);
  }
}

@mixin dashboard-toolbar-theme($theme) {
  glb-dashboard-toolbar {
    @include toolbar-form-theme($theme);
    @include _dashboard-toolbar-button-toggle-theme($theme);
  }
}

@mixin dashboard-toolbar-typography($config) {
  glb-dashboard-toolbar {
    @include toolbar-form-typography($config);
  }
}
