@use 'sass:math';

$target-height: 23px;
$font-size: 11px;
$vertical-padding: math.div($target-height - $font-size, 2);

.mat-tooltip {
  font-size: $font-size;
  padding-top: $vertical-padding;
  padding-bottom: $vertical-padding;
}
