::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: darkgrey;

  &:hover {
    background-color: darken(darkgrey, 20);
  }
}
