@use '@angular/material' as mat;
@use 'sass:map';

@mixin color($theme) {
  $color-config: mat.get-color-config($theme);
  $background: map.get($color-config, 'background');

  glb-settings {
    .mat-sidenav-content {
      background: mat.get-color-from-palette($background, card);
    }
  }
}

@mixin typography($theme) {
  $typography-config: mat.get-typography-config($theme);

  glb-settings {
  }
}

@mixin theme($theme) {
  $color-config: mat.get-color-config($theme);
  @if $color-config != null {
    @include color($theme);
  }

  $typography-config: mat.get-typography-config($theme);
  @if $typography-config != null {
    @include typography($theme);
  }
}
