@use '@angular/material' as mat;
@use 'sass:map';
@import '@angular/material/theming';

@mixin color($theme) {
  $color-config: mat.get-color-config($theme);
  $primary: map.get($color-config, primary);
  $foreground: map-get($theme, foreground);
  $background: map-get($theme, background);

  glb-task-address-type {
    .mat-button-toggle {
      .mat-button-toggle-button {
        border: 2px solid mat.get-color-from-palette($primary);
        color: mat.get-color-from-palette($primary);
        width: 100px;
      }

      &.mat-button-toggle-checked {
        background-color: mat.get-color-from-palette($primary);

        .mat-button-toggle-label-content {
          color: $light-primary-text;
        }
      }
    }

    .mat-button-toggle-disabled {
      color: mat.get-color-from-palette($foreground, disabled-button);
      background-color: $light-primary-text;

      .mat-button-toggle-button {
        border: 2px solid mat.get-color-from-palette($background, selected-disabled-button);
        color: mat.get-color-from-palette($background, selected-disabled-button);
      }

      &.mat-button-toggle-checked {
        background-color: mat.get-color-from-palette($background, selected-disabled-button);
      }
    }
  }
}

@mixin typography($theme) {
  $typography-config: mat.get-typography-config($theme);
  glb-task-address-type {
    .mat-button-toggle-label-content {
      line-height: 10px;
      font-size: 14px;
    }
  }
}

@mixin theme($theme) {
  $color-config: mat.get-color-config($theme);
  @if $color-config != null {
    @include color($theme);
  }

  $typography-config: mat.get-typography-config($theme);
  @if $typography-config != null {
    @include typography($theme);
  }
}
