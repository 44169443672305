// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use '@angular/material' as mat;
@use 'styles/custom-components' as custom-components;
@use 'styles/toolbar';

@import 'styles/ngx-toastr';

$geotask-client-typography: mat.define-typography-config();

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core($geotask-client-typography);

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$geotask-client-primary: mat.define-palette(mat.$blue-palette);
$geotask-client-accent: mat.define-palette(mat.$orange-palette);

// Create the theme object (a Sass map containing all of the palettes).
$geotask-client-theme: mat.define-light-theme($geotask-client-primary, $geotask-client-accent);

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($geotask-client-theme);
@include custom-components.typography($geotask-client-typography);
@include custom-components.theme($geotask-client-theme);

/* You can add global styles to this file, and also import other style files */

html,
body {
  height: 100%;
}

body {
  margin: 0;
  font-family: Roboto, 'Helvetica Neue', sans-serif;
}

#google-map {
  height: 100%;
  width: 100%;

  &.hidden {
    display: none;
  }
}

@import 'styles/fonts/roboto/font-faces.css';
@import 'styles/fonts/material-icons/font-face.css';

// Bryntum Scheduler styles
@import '~@bryntum/scheduler/scheduler.material.css';
@import 'styles/scheduler/style';

// Custom styling stylesheets
@import 'styles/scrollbar';
@import 'styles/progress-bar-wrapper';
@import 'styles/mat-tooltip';

.dialog-close-button {
  float: right;
  top: -24px;
  right: -24px;
}

.mat-dialog-actions {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: flex-end;
}

.mat-option--with-text-overflow > .mat-option-text {
  text-overflow: unset;
  overflow: auto;
}

.appointments-dialog .mat-dialog-container {
  padding: 0;
}

.mat-select-panel.status-filter-options-panel {
  max-height: unset;
}

@include toolbar.styles;
