@use '@angular/material' as mat;
@use 'variables';
@use 'sass:map';

@mixin color($theme) {
  $color-config: mat.get-color-config($theme);
  $primary: map.get($color-config, primary);
  $accent: map.get($color-config, accent);
  $warn: map.get($color-config, warn);
  $foreground: map.get($color-config, foreground);
  $background: map.get($color-config, background);
  $is-dark-theme: map.get($color-config, is-dark);

  .cdk-drag-preview.task-additional-field-drag-preview {
    border-radius: variables.$panel-border-radius;
    background-color: mat.get-color-from-palette($background, card);
  }

  glb-task-additional-fields-config {
    .cdk-drag:hover {
      @include mat.elevation(3);
    }
  }
}

@mixin typography($theme) {
  $typography-config: mat.get-typography-config($theme);
}

@mixin theme($theme) {
  $color-config: mat.get-color-config($theme);
  @if $color-config != null {
    @include color($theme);
  }

  $typography-config: mat.get-typography-config($theme);
  @if $typography-config != null {
    @include typography($theme);
  }
}
