@mixin mat-form-field-without-label-typography($config) {
  // The amount of overlap between the label and the outline.
  $mat-form-field-outline-label-overlap: 0;
  $infix-padding: 0.5em;
  $infix-margin-top: 0;

  .mat-form-field-infix {
    border-top: $infix-margin-top solid transparent;
  }

  .mat-form-field-label-wrapper {
    top: -$infix-margin-top;
    padding-top: $infix-margin-top;
  }

  .mat-form-field-label {
    top: $infix-margin-top + $infix-padding;
    margin-top: unset;
  }

  &.mat-form-field-appearance-outline {
    .mat-form-field-wrapper {
      margin: $mat-form-field-outline-label-overlap 0;
    }

    .mat-select-arrow-wrapper {
      transform: unset;
    }

    .mat-form-field-flex {
      margin-top: -$mat-form-field-outline-label-overlap;
    }

    .mat-form-field-prefix,
    .mat-form-field-suffix {
      top: unset;
      align-self: center;
    }

    .mat-form-field-outline {
      top: $mat-form-field-outline-label-overlap;
    }
  }
}
