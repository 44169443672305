@use '@angular/material' as mat;

@mixin datepicker-form-field-control-theme($theme) {
  $primary: map-get($theme, primary);
  $accent: map-get($theme, accent);
  $foreground: map-get($theme, foreground);
  $background: map-get($theme, background);

  .mat-calendar-body-cell.multiSelectSelected > .mat-calendar-body-cell-content,
  .mat-calendar-body-cell.multiSelectSelected:hover > .mat-calendar-body-cell-content,
  .mat-calendar-body-cell.multiSelectSelected > .mat-calendar-body-cell-content:hover {
    background-color: mat.get-color-from-palette($primary, default) !important;
  }
}
