@use '@angular/material' as mat;
@use 'sass:map';

@import 'src/styles/mixins/form-field-without-label.theme';
@import 'src/styles/mixins/form-field-without-subscript.theme';

@mixin color($theme) {
  $color-config: mat.get-color-config($theme);
  $foreground: map-get($theme, foreground);

  .mat-form-field-appearance-outline {
    .mat-form-field-infix {
      font-weight: 400;
    }

    .mat-form-field-label {
      font-weight: 300;
    }
  }

  textarea.mat-input-element.cdk-textarea-autosize {
    line-height: 1.5;
  }

  .mat-form-field-appearance-outline.mat-form-field-disabled .mat-form-field-label {
    color: mat.get-color-from-palette(mat.$grey-palette, 600);
  }

  .mat-form-field-appearance-outline.mat-form-field-disabled .mat-form-field-outline {
    background-color: mat.get-color-from-palette(mat.$grey-palette, 100);
    border-radius: 5px;
  }

  .mat-input-element:disabled,
  .mat-select-disabled .mat-select-value {
    color: mat.get-color-from-palette($foreground, text);
  }
  .mat-slide-toggle.mat-disabled {
    opacity: 0.6 !important;
  }
}

@mixin typography($theme) {
  .mat-form-field.mat-form-field--without-label {
    @include mat-form-field-without-label-typography($theme);
  }
  .mat-form-field.mat-form-field--without-subscript {
    @include mat-form-field-without-subscript-typography($theme);
  }
}

@mixin theme($theme) {
  $color-config: mat.get-color-config($theme);
  @if $color-config != null {
    @include color($theme);
  }

  $typography-config: mat.get-typography-config($theme);
  @if $typography-config != null {
    @include typography($theme);
  }
}
