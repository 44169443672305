@use '@angular/material' as mat;
@use 'sass:math';

@mixin _outline-border-and-datepicker($palette) {
  .mat-form-field-outline-start,
  .mat-form-field-outline-end {
    border-color: mat.get-color-from-palette($palette, default-contrast);
  }

  .mat-form-field-outline-gap {
    border-color: mat.get-color-from-palette($palette, default-contrast);
  }

  .mat-datepicker-toggle,
  .mat-datepicker-toggle-active {
    color: mat.get-color-from-palette($palette, 800);
  }
}

@mixin _mat-form-field-outline-theme($theme) {
  $primary: map-get($theme, primary);
  $accent: map-get($theme, accent);
  $warn: map-get($theme, warn);
  $foreground: map-get($theme, foreground);
  $background: map-get($theme, background);
  $is-dark-theme: map-get($theme, is-dark);

  $outline-color-hover: mat.get-color-from-palette($foreground, divider, if($is-dark-theme, 1, 0.5));
  $outline-color-primary: mat.get-color-from-palette($primary, darker);
  $outline-color-accent: mat.get-color-from-palette($accent, darker);
  $outline-color-warn: mat.get-color-from-palette($warn, darker);

  .mat-form-field-appearance-outline {
    color: mat.get-color-from-palette($primary, 800);

    .mat-form-field-outline {
      background-color: mat.get-color-from-palette($background, raised-button);
      border-radius: 1.5px;
    }

    @include _outline-border-and-datepicker($primary);

    &.mat-accent {
      @include _outline-border-and-datepicker($accent);
    }

    &.mat-warn {
      @include _outline-border-and-datepicker($warn);
    }

    .mat-form-field-outline-thick {
      color: $outline-color-hover;
    }

    &.mat-focused {
      .mat-form-field-outline-thick {
        color: $outline-color-primary;
      }

      &.mat-accent .mat-form-field-outline-thick {
        color: $outline-color-accent;
      }

      &.mat-warn .mat-form-field-outline-thick {
        color: $outline-color-warn;
      }
    }
  }
}

@mixin toolbar-form-theme($theme) {
  @include _mat-form-field-outline-theme($theme);
}
// ---------------------------- Typography ----------------------------

@mixin toolbar-form-typography($config) {
  @include _toolbar-form-field-typography($config);
  @include _toolbar-form-field-outline-typography($config);
}

@mixin _toolbar-form-field-typography($config) {
  // The padding applied to the form-field-wrapper to reserve space for the subscript, since it's
  // absolutely positioned. This is a combination of the subscript's margin and line-height, but we
  // need to multiply by the subscript font scale factor since the wrapper has a larger font size.
  $wrapper-padding-bottom: 0;

  .mat-form-field.mat-form-field--toolbar {
    font-size: 12px;
    font-weight: bold;
    line-height: unset;
  }

  .mat-form-field-wrapper {
    padding-bottom: $wrapper-padding-bottom;
  }

  .mat-form-field-underline {
    // We want the underline to start at the end of the content box, not the padding box,
    // so we move it up by the padding amount.
    bottom: $wrapper-padding-bottom;
  }

  .mat-form-field-subscript-wrapper {
    // We want the subscript to start at the end of the content box, not the padding box,
    // so we move it up by the padding amount (adjusted for the smaller font size);
    top: calc(100%);
  }
}

@mixin _toolbar-form-field-outline-typography($config) {
  // The unit-less line-height from the font config.
  $line-height: mat.line-height($config, input);
  // The amount to scale the font for the floating label and subscript.
  $subscript-font-scale: 0.75;
  // The margin applied to the form-field-infix to reserve space for the floating label.
  $infix-margin-top: 1em * $line-height * $subscript-font-scale;
  // The padding above and below the infix.
  $infix-padding: 0.5em;
  // The amount we offset the label from the input text in the outline appearance.
  $outline-appearance-label-offset: -0.25em;

  .mat-form-field-appearance-outline {
    .mat-form-field-infix {
      padding: $infix-padding 0 $infix-padding 0;
    }

    .mat-form-field-label {
      top: $infix-padding + $infix-margin-top;
      margin-top: $outline-appearance-label-offset;
    }

    &.mat-form-field-can-float {
      &.mat-form-field-should-float .mat-form-field-label,
      .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label {
        @include _label-floating(
          $subscript-font-scale,
          $infix-padding + $outline-appearance-label-offset,
          $infix-margin-top
        );
      }
    }
  }
}

// Applies a floating label above the form field control itself.
@mixin _label-floating($font-scale, $infix-padding, $infix-margin-top) {
  transform: translateY(-$infix-margin-top - $infix-padding) scale($font-scale);
  width: math.div(100%, $font-scale);
}
