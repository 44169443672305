@use '@angular/material' as mat;
@use 'sass:map';

@mixin color($theme) {
  $color-config: mat.get-color-config($theme);
  $primary: map.get($color-config, primary);
  $accent: map.get($color-config, accent);
  $warn: map.get($color-config, warn);
  $foreground: map.get($color-config, foreground);
  $background: map.get($color-config, background);
  $is-dark-theme: map.get($color-config, is-dark);
  $taskHighlight: #8a39e1;

  .b-tooltip.task-tooltip {
    min-width: 14em;
    max-width: 28em;

    .b-tooltip-content {
      background-color: mat.get-color-from-palette($background, background);
      color: mat.get-color-from-palette($foreground, text);

      .badge {
        border-radius: 4px;
        width: 100%;
        background-color: mat.get-color-from-palette($primary);
        color: mat.get-color-from-palette($primary, default-contrast);
        padding: 0.25rem 0.5rem;
        text-align: center;
      }
      .header_details {
        padding-left: 5px;
        p {
          margin: 0;
          padding: 1px;
        }
      }

      .header,
      .footer {
        display: flex;
        flex-flow: row;
        justify-content: space-between;
        align-items: center;
      }

      .header {
        border-bottom: 1px solid mat.get-color-from-palette($primary, darker);
        display: inline-flex;
        margin-bottom: 0.5rem;
        padding-bottom: 0.5rem;
      }

      .footer {
        margin-top: 0.5rem;

        .task-status {
          margin-right: 0.5rem;
          width: auto;
        }

        .task-duration {
          span {
            margin-right: 0.5rem;
          }
        }
        .travel-duration {
          margin-right: 1rem;
          span {
            margin-right: 0.5rem;
          }
        }
      }
    }
  }

  .b-tooltip.team-tooltip {
    .tooltip-section {
      padding-bottom: 10px;
    }
    .param--exceeded {
      color: mat.get-color-from-palette($warn);
    }
    .tab-button {
      border-bottom: 0.5px solid mat.get-color-from-palette($primary, default-contrast);
    }
    .selected {
      border-bottom-width: 3px;
    }
  }

  glb-dashboard-scheduler {
    $selection-color: $primary;
    $selection-hue: if($is-dark-theme, 800, 100);
    $selection-hue-on-hover: if($is-dark-theme, 700, 200);

    .b-grid-header-container {
      background-color: unset;
    }

    &,
    .b-grid-header-container .b-sch-timeaxiscolumn {
      background-color: mat.get-color-from-palette($background, app-bar);
    }

    .b-gridbase:not(.b-moving-splitter) .b-grid-row.b-selected {
      .b-grid-cell:not(.b-sch-timeaxis-cell) {
        background-color: mat.get-color-from-palette($selection-color, $selection-hue);
        color: mat.get-contrast-color-from-palette($selection-color, $selection-hue);
      }

      &.b-hover .b-grid-cell:not(.b-sch-timeaxis-cell) {
        background-color: mat.get-color-from-palette($selection-color, $selection-hue-on-hover);
        color: mat.get-contrast-color-from-palette($selection-color, $selection-hue-on-hover);
      }
    }

    .b-sch-resourcetimerange {
      &.locked-by-optimization {
        background-color: mat.get-color-from-palette($background, tooltip);
        background-image: repeating-linear-gradient(
          45deg,
          transparent,
          transparent 15px,
          rgba(255, 255, 255, 0.5) 15px,
          rgba(255, 255, 255, 0.5) 30px
        );
      }

      &.non-working-time {
        background: mat.get-color-from-palette(mat.$grey-palette, 200, 0.5);
      }
    }

    .b-grid-row.team {
      border-bottom: 1px solid mat.get-color-from-palette(mat.$grey-palette, 400, 0.5);
      &.team--limits-exceeded {
        .b-grid-cell {
          color: mat.get-color-from-palette($warn);
          font-weight: 600;
        }
      }
    }

    .b-horizontaltimeaxis .b-sch-header-row:first-of-type {
      border-bottom: 1px solid mat.get-color-from-palette(mat.$grey-palette, 300);
    }

    .b-grid-header-container {
      box-shadow: none;
      border-bottom: 1px solid mat.get-color-from-palette(mat.$grey-palette, 300);
    }

    .b-column-line {
      border-color: mat.get-color-from-palette(mat.$grey-palette, 300);
    }

    .b-grid-subgrid-locked,
    .b-grid-header-scroller-locked {
      background-color: mat.get-color-from-palette(mat.$grey-palette, 300, 0.5);
    }

    .b-virtual-scrollers .b-grid-splitter,
    .b-virtual-scroller:first-child {
      background-color: mat.get-color-from-palette(mat.$grey-palette, 300, 0.5);
    }

    .mat-form-field-outline {
      &-start,
      &-gap,
      &-end {
        background-color: mat.get-color-from-palette($background, card);
      }
    }

    .b-grid-header .b-sch-timerange.b-sch-current-time {
      background-color: mat.get-color-from-palette($primary);
    }
    .b-timeline-subgrid .b-sch-current-time {
      border-left-color: mat.get-color-from-palette($primary);
    }
    .b-sch-event-wrap:not(.b-milestone-wrap) .b-sch-event.b-sch-event-selected {
      background-color: $taskHighlight;
    }
  }
}

@mixin typography($theme) {
  $typography-config: mat.get-typography-config($theme);
}

@mixin theme($theme) {
  $color-config: mat.get-color-config($theme);
  @if $color-config != null {
    @include color($theme);
  }

  $typography-config: mat.get-typography-config($theme);
  @if $typography-config != null {
    @include typography($theme);
  }
}
