@use '@angular/material' as mat;
@use 'sass:map';

@mixin color($theme) {
  $color-config: mat.get-color-config($theme);
  $primary: map.get($color-config, primary);
  $background: map.get($color-config, background);

  glb-appointments-table {
    .mat-button-toggle-group-appearance-standard .mat-button-toggle + .mat-button-toggle {
      border: none;
    }

    .mat-button-toggle-disabled.mat-button-toggle-appearance-standard {
      background: mat.get-color-from-palette($background, disabled-button-toggle);
    }

    .mat-button-toggle-group-appearance-standard .mat-button-toggle.available {
      border: 1px solid mat.get-color-from-palette($primary, 500);
    }

    .mat-button-toggle-checked {
      $checked-hue: 500;
      $checked-palette: $primary;

      background: mat.get-color-from-palette($checked-palette, $checked-hue);

      span {
        color: mat.get-contrast-color-from-palette($checked-palette, $checked-hue);
      }
    }
  }
}

@mixin typography($theme) {
  $typography-config: mat.get-typography-config($theme);
}

@mixin theme($theme) {
  $color-config: mat.get-color-config($theme);
  @if $color-config != null {
    @include color($theme);
  }

  $typography-config: mat.get-typography-config($theme);
  @if $typography-config != null {
    @include typography($theme);
  }
}
