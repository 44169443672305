@use 'variables';

@mixin styles {
  .glb-toolbar {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: variables.$spacing;
    overflow: hidden;

    &__header {
      flex: 0 1 auto;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    &__actions {
      display: flex;
      gap: variables.$spacing-dense;
      align-items: baseline;
    }
  }
}
