@use '@angular/material' as mat;
@use 'sass:map';

@mixin color($theme) {
  $color-config: mat.get-color-config($theme);
  $primary: map.get($color-config, 'primary');
  $accent: map.get($color-config, 'accent');

  .mat-button-base {
    &.mat-flat-button {
      &:not([color]) {
        color: mat.get-color-from-palette($primary, 800);
      }
      font-size: 13px;
      line-height: 2.25;
      border-width: 1px;
      border-radius: 1.5px;
      border-style: solid;
      border-color: mat.get-color-from-palette($primary, default-contrast);
      .mat-icon {
        transform: scale(0.85);
      }
    }
  }
}

@mixin typography($theme) {
  $typography-config: mat.get-typography-config($theme);

  .mat-button-base:not(.mat-icon-button) {
    text-transform: uppercase;
  }

  .mat-button--small:not(.mat-icon-button) {
    font-size: 12px;
    line-height: 16px;
    height: 28px;
  }
}

@mixin theme($theme) {
  $color-config: mat.get-color-config($theme);
  @if $color-config != null {
    @include color($theme);
  }

  $typography-config: mat.get-typography-config($theme);
  @if $typography-config != null {
    @include typography($theme);
  }
}
